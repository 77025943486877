import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import DashboardScreen from "../screens/DashboardScreen/DashboardScreen";
import SearchScreen from "../screens/SearchScreen/SearchScreen";
import ChatScreen from "../screens/ChatScreen/ChatScreen";
import ChatHistory from "../screens/ChatScreen/ChatHistory";
import SettingsScreen from "../screens/SettingsScreen/SettingsScreen";
import LandingScreen from "../screens/LandingScreen/LandingScreen";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import Navbar from "../components/Navbar";
import { jwtDecode } from "jwt-decode";
import { logout } from "../redux/actions/authAction";
import NotFoundScreen from "../screens/NotFoundScreen/NotFoundScreen";
import SignupScreen from "../screens/SingupScreen/SignupScreen";
import SignupConfirmationScreen from "../screens/SingupScreen/SignupConfirmationScreen";
import ForgotPasswordScreen from "../screens/LoginScreen/ForgotPasswordScreen";
import ResetPasswordScreen from "../screens/LoginScreen/ResetPasswordScreen";

// PrivateRoute component to protect routes
const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.auth.user);

  return user ? children : <Navigate to="/login" />;
};

// AuthWrapper component to handle token expiration checks
const AuthWrapper = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to check token expiration
  const isTokenExpired = (token) => {
    if (!token) return true;
    const { exp } = jwtDecode(token);
    return Date.now() >= exp * 1000;
  };

  useEffect(() => {
    
    // List of allowed public routes that don't require authentication
    const publicRoutes = [
      "/login",
      "/signup",
      "/signup/confirmation",
      "/forgot-password",
      "/reset-password",
      "/not-found-screen",
    ];

    // If the current route is not public, check user and token status
    if (!publicRoutes.includes(location.pathname)) {
      // Redirect to login if user does not exist
      if (!user) {
        navigate("/login");
        return;
      }

      // Check token expiration, logout, and redirect if expired
      if (isTokenExpired(user?.token?.access_token)) {
        dispatch(logout());
        navigate("/login");
      }
    }
  }, [user, dispatch, navigate, location.pathname]);

  return children;
};

const AppRouter = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Router>
      <AuthWrapper>
        <div style={{ display: "flex" }}>
          {user && <Navbar />}
          <div style={{ flexGrow: 1, padding: "20px" }}>
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<LoginScreen />} />
              <Route
                path="/forgot-password"
                element={<ForgotPasswordScreen />}
              />
              <Route path="/reset-password" element={<ResetPasswordScreen />} />
              <Route path="/signup" element={<SignupScreen />} />
              <Route
                path="/signup/confirmation"
                element={<SignupConfirmationScreen />}
              />
              {/* Signup Confirmation Route */}
              {/* <Route path="/" element={<LandingScreen />} /> */}
              {/* Private Routes */}
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <DashboardScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="/search"
                element={
                  <PrivateRoute>
                    <SearchScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="/conversation/:conversationId"
                element={
                  <PrivateRoute>
                    <ChatScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="/conversation"
                element={
                  <PrivateRoute>
                    <ChatHistory />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <SettingsScreen />
                  </PrivateRoute>
                }
              />
              {/* Not Found Route */}
              <Route path="/not-found-screen" element={<NotFoundScreen />} />
              {/* Catch-All Route */}
              <Route path="*" element={<Navigate to="/not-found-screen" />} />
            </Routes>
          </div>
        </div>
      </AuthWrapper>
    </Router>
  );
};

export default AppRouter;
