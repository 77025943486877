import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersByOrganization } from "../../../redux/actions/userAction";

const ShareConversationModal = ({
  open,
  onClose,
  onShare,
  organizationName,
}) => {
  const dispatch = useDispatch();
  const { users, loading } = useSelector((state) => state.user);
  const [selectedUsers, setSelectedUsers] = useState([]); // Store user objects
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      dispatch(fetchUsersByOrganization(organizationName));
    }
  }, [open, organizationName, dispatch]);

  const handleToggle = (user) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.some((u) => u.id === user.id)
        ? prevSelected.filter((u) => u.id !== user.id)
        : [...prevSelected, user]
    );
  };

  const handleShare = async () => {
    setIsLoading(true);
    const selectedUsersIds = selectedUsers.map((user) => user.id);
    await onShare(selectedUsersIds); // Pass the selected user objects
    onClose();
    setIsLoading(false);
  };

  // Filter users based on search term
  const filteredUsers = users.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Partager la conversation
        </Typography>

        {/* Search Bar */}
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Rechercher un utilisateur..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 2 }}
        />

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <List sx={{ maxHeight: 300, overflowY: "auto" }}>
            {filteredUsers.map((user) => (
              <ListItem key={user.id} onClick={() => handleToggle(user)}>
                <ListItemAvatar>
                  <Avatar>
                    {user.first_name ? user.first_name[0] : ""}
                    {user.last_name ? user.last_name[0] : ""}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.first_name} ${user.last_name}`}
                  secondary={user.email}
                />
                <Checkbox
                  edge="end"
                  checked={selectedUsers.some((u) => u.id === user.id)}
                  onChange={() => handleToggle(user)}
                />
              </ListItem>
            ))}
          </List>
        )}

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleShare}
            disabled={selectedUsers.length === 0 || loading || isLoading} // Disable button when loading
            startIcon={isLoading ? <CircularProgress size={20} /> : null} // Show CircularProgress when loading
          >
            {isLoading ? "" : "Partager"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareConversationModal;
