import React from "react";
import { isMobile } from "../../../utils/utils";
import useDocumentStore from "./useDocumentStore";
import { Chip } from "@mui/material";

const CitationDisplay = ({ citation }) => {
  const {
    setSelectedCitation,
    setLoading,
    selectedCitation,
    showModalIfMobile,
  } = useDocumentStore();

  const { short_title, long_title, section_title } = citation;

  const handleCitationClick = (documentId, pageNumber) => {
    if (
      selectedCitation?.metadata?.document_id ===
      citation?.metadata?.document_id
    )
      return;

    // Update citation in store, don't push a new history entry
    setSelectedCitation({
      ...citation,
      document_id: citation?.metadata?.document_id,
    });

    setLoading(true); // Set loading state when a new citation is clicked

    // Open modal if on a mobile device
    if (isMobile()) {
      showModalIfMobile();
    }
  };

  return (
    <div
      className={`mx-1.5 mb-2 min-h-[25px] min-w-[160px] cursor-pointer rounded border-l-8 bg-gray-00 p-1 hover:bg-gray-15`}
      onClick={() => {
        console.log("toto", citation);
        handleCitationClick(
          citation?.metadata?.documentId,
          citation?.metadata?.pageNumber
        );
      }}
    >
      <div className="flex flex-col">
        {/* Display long_title */}
        <div className="text-xs font-bold text-black line-clamp-2 overflow-hidden text-ellipsis whitespace-normal leading-tight">
          {citation?.metadata?.long_title}
        </div>
        {/* Display section_title and Chip on the same line */}
        <div className="flex items-center justify-between mt-1">
          <div className="text-xs text-gray-700 line-clamp-1 overflow-hidden text-ellipsis whitespace-normal">
            {citation?.metadata?.section_title
              .replace(":", "")
              .replace("N/A ", "")
              .toLowerCase()}
          </div>
          <Chip
            label={citation?.metadata?.doc_type}
            variant="outlined"
            size="small"
            sx={{
              fontSize: "0.75rem", // Small font size
              color: (() => {
                switch (citation?.metadata?.doc_type) {
                  case "Arrêté":
                    return "#1976d2"; // Blue
                  case "Décret":
                    return "#388e3c"; // Green
                  case "Dahir":
                    return "#d32f2f"; // Red
                  default:
                    return "#555"; // Default color
                }
              })(),
              borderColor: (() => {
                switch (citation?.metadata?.doc_type) {
                  case "Arrêté":
                    return "#1976d2";
                  case "Décret":
                    return "#388e3c";
                  case "Dahir":
                    return "#d32f2f";
                  default:
                    return "#555";
                }
              })(),
            }}
          />
        </div>
      </div>
      <p className="line-clamp-2 text-[10px] font-light leading-3">
        {citation?.page_content}
      </p>
    </div>
  );
};

export default CitationDisplay;
