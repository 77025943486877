import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import SubProcessDisplay from "./SubProcessDisplay";
import { Edit as EditIcon } from "@mui/icons-material";
import useConversationsStore from "./useConversationsStore";
import ErrorMessageDisplay from "./ErrorMessageDisplay";
import { TextDirection } from "../../../utils/constants";
import { Tooltip } from "@mui/material";

const LazyTextEditor = lazy(() => import("../../../components/QuillEditor"));

const AssistantDisplay = ({ message, documents }) => {
  const { editMessage, checkMessage, currentLanguage, messages } =
    useConversationsStore();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [content, setContent] = useState(message.content); // Ensure this handles editor changes

  const DynamicTextEditor = useMemo(() => {
    return (
      <Suspense fallback={<p>loading...</p>}>
        <LazyTextEditor value={content} setValue={setContent} />
      </Suspense>
    );
  }, [content]);

  const isMessageSuccessful = message.status === "SUCCESS";
  const isMessageError = message.status === "ERROR";

  useEffect(() => {
    setContent(message.content);
  }, [message]);

  useEffect(() => {
    if (isMessageSuccessful) {
      setIsExpanded(false);
    }
  }, [isMessageSuccessful]);

  const handleSave = () => {
    // Call the editMessage function with the new content
    editMessage(message.id, content);
    setIsEditing(false); // Close the editor after saving
  };

  const formatTextToHTML = (text) => {
    // Replace ** for bold with <strong> tag
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    // Replace * for italics with <em> tag
    formattedText = formattedText.replace(/\*(.*?)\*/g, "<em>$1</em>");
    return formattedText;
  };

  return (
    <div
      className="pb-4 pr-5"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="flex">
        <div className="pl-11 w-full">
          {!isMessageError && (
            <div className="flex flex-col flex-grow">
              <SubProcessDisplay
                key={`${message.id}-sub-process`}
                subProcesses={message.sub_processes || []}
                isOpen={isExpanded}
                toggleOpen={() => setIsExpanded((prev) => !prev)}
                showSpinner={!isMessageSuccessful}
                messageId={message.id}
                documents={documents}
                like={message.like}
              />
            </div>
          )}
          {isMessageError && <ErrorMessageDisplay />}
        </div>
      </div>

      {!isMessageError && (
        <>
          <div className="flex items-center justify-center">
            <div className="my-3 w-11/12 border-[.5px]"></div>
          </div>
          <div className="flex items-center">
            <div
              className={`h-12 w-1/5 
                ${
                  // currentLanguage?.textDirection === TextDirection.RightToLeft
                  // ? "order-2"
                  // :
                  ""
                }`}
            >
              {!isEditing && message.status === "SUCCESS" && (
                // <Tooltip title="Sélectionner" placement="bottom">
                <p
                  className=" flex cursor-pointer items-center pl-2 pr-6"
                  // onClick={() => checkMessage(message.id)}
                >
                  <label className="flex items-center justify-end ">
                    <input
                      className={
                        ""
                        // currentLanguage?.textDirection ===
                        // TextDirection.RightToLeft
                        //   ? "order-2"
                        //   : ""
                      }
                      type="checkbox"
                      checked={message?.checked || false}
                      onChange={() => checkMessage(message.id)}
                    />
                    {/* {"Exporter"} */}
                  </label>
                </p>
                // </Tooltip>
              )}
            </div>
            <div sx={{}}>
              {isEditing ? (
                <div>
                  <Suspense fallback={<p>loading...</p>}>
                    <LazyTextEditor value={content} setValue={setContent} />
                  </Suspense>
                  <div className="my-2 flex justify-end gap-3">
                    <span
                      onClick={handleSave} // Call handleSave on click
                      className="cursor-pointer text-sm underline"
                    >
                      {/* {currentLanguage?.saveText} */}
                      {"Sauvegarder"}
                    </span>
                    <span
                      onClick={() => {
                        setIsEditing(false);
                      }}
                      className="cursor-pointer text-sm underline"
                    >
                      {/* {currentLanguage?.cancelText} */}
                      {"Annuler"}
                    </span>
                  </div>
                </div>
              ) : (
                <>
                  {!isEditing && message.status === "SUCCESS" && (
                    <p
                      className={`flex w-20 cursor-pointer items-center gap-2 ${
                        // currentLanguage?.textDirection ===
                        // TextDirection.RightToLeft
                        //   ? "mx-6 mr-auto"
                        //   :
                        "ml-auto"
                      }`}
                      onClick={() => setIsEditing(true)}
                    >
                      <EditIcon /> {"Éditer"}
                    </p>
                  )}
                  <div className="relative mb-2 mt-2 whitespace-pre-wrap font-nunito text-sm text-gray-90">
                    <p
                      className="ql-editor whitespace-pre-wrap font-nunito text-sm  text-gray-90"
                      style={
                        {
                          // direction:
                          //   currentLanguage?.textDirection ===
                          //   TextDirection.RightToLeft
                          //     ? "rtl"
                          //     : "ltr",
                          // textAlign:
                          //   currentLanguage?.textDirection ===
                          //   TextDirection.RightToLeft
                          //     ? "right"
                          //     : "left",
                        }
                      }
                      dangerouslySetInnerHTML={{
                        __html: formatTextToHTML(message.content),
                      }}
                    ></p>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      <p
        className="flex ml-10 justify-start p-1 text-xs text-gray-60"
        style={
          {
            // direction:
            //   currentLanguage?.textDirection === TextDirection.RightToLeft
            //     ? "rtl"
            //     : "ltr",
          }
        }
      >
        {
          "Cette déclaration est uniquement à des fins d'information et ne constitue pas un conseil juridique professionnel."
        }
      </p>
    </div>
  );
};

export default AssistantDisplay;
