import React, { useEffect, useState, useRef } from "react";
import { CircularProgress, Box, IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import useDocumentStore from "../../screens/ChatScreen/components/useDocumentStore";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { useSnackbar } from "notistack";
import html2pdf from "html2pdf.js";
import { backendClient } from "../../api/backend";
import { jsPDF } from "jspdf";
import Fuse from "fuse.js"; // Import Fuse.js for fuzzy search


// Custom color configuration for highlighting
const HIGHLIGHT_CLASSNAME = "opacity-40 saturate-[3] highlighted-by-llama ";
const highlightColor = "background-color: yellow;"; // Define the highlight color


// Helper function to split HTML content into sentences while preserving the structure
const splitHtmlIntoSentences = (htmlContent) => {
  // Use regex to split by punctuation without breaking HTML tags
  return htmlContent.match(/[^.!?]+[.!?]+[\])'"`’”]*|.+$/g);
};

// Function to highlight the matching sentence within the HTML content
const multiHighlightHtml = (htmlContent, searchText) => {
  const sentences = splitHtmlIntoSentences(htmlContent);
  if (!searchText || searchText.trim() === "") return htmlContent;

  const fuse = new Fuse(sentences, {
    includeScore: true,
    threshold: 0.3,
    keys: ["text"], // Search within the text content
  });

  const result = fuse.search(searchText);
  if (result.length > 0) {
    const searchResult = result[0].item;
    const highlightedSentence = `<span style="${highlightColor}">${searchResult}</span>`;
    return htmlContent.replace(searchResult, highlightedSentence);
  }

  return htmlContent;
};



export default function HtmlViewer() {
  const { loading, setLoading, selectedCitation } = useDocumentStore();
  const { enqueueSnackbar } = useSnackbar();
  const [htmlContent, setHtmlContent] = useState(null);
  const iframeRef = useRef(null);
  const [selectedCitationUrl, setSelectedCitationUrl] = useState(null);
  const [originalHtmlContent, setOriginalHtmlContent] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  // Function to highlight text in HTML content
  const highlightTextInContent = (content, sectionTitle) => {
    if (sectionTitle) {
      return multiHighlightHtml(content, sectionTitle);
    }
    return content;
  };

  const handleExport = () => {
    setIsDownloading(true);

    const filename = selectedCitation?.metadata?.long_title
      ? `${selectedCitation?.metadata?.long_title}.pdf`
      : "document.pdf";

    const pdf = new jsPDF("p", "pt", "a4"); // Create a new PDF document in A4

    pdf.html(originalHtmlContent, {
      callback: (doc) => {
        doc.save(filename);
        setIsDownloading(false);
        enqueueSnackbar("Document exporté avec succès!", {
          variant: "success",
        });
      },
      margin: [20, 10, 20, 10],
      x: 10,
      y: 10,
      html2canvas: { scale: 0.62 },
      autoPaging: "text",
      width: 190,
      windowWidth: 900,
      pagebreak: { mode: ["avoid-all"], after: ["p"] },
    });
  };

  const scrollToHighlightedElement = () => {
    const iframeDocument = iframeRef.current?.contentWindow?.document;
    const highlightedElement = iframeDocument?.getElementById(
      "highlighted-section"
    );
    if (highlightedElement) {
      highlightedElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    const fetchAndHighlightContent = async () => {
      const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
      const blobName = selectedCitation?.metadata?.file_name;

      try {
        setLoading(true);
        const data = await backendClient.getDocumentSas(
          containerName,
          blobName
        );
        const response = await fetch(data.blob_url_with_sas);
        setSelectedCitationUrl(response.url);
        let content = await response.text();

        setOriginalHtmlContent(content);

        // if (selectedCitation?.section_title) {
        //   content = highlightTextInContent(
        //     content,
        //     selectedCitation.text
        //   );
        // }

        setHtmlContent(content); // Update HTML content with highlights
        setLoading(false);
      } catch (error) {
        console.error("Error fetching or processing the document:", error);
        enqueueSnackbar("Failed to fetch and process the document.", {
          variant: "error",
        });
      }
    };

    if (selectedCitation?.metadata?.file_name) {
      fetchAndHighlightContent();
    } else {
      setHtmlContent(null);
    }
  }, [selectedCitation]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingBottom: "8px",
        }}
      >
        {htmlContent && (
          <IconButton
            onClick={handleExport}
            aria-label="export"
            disabled={isDownloading}
          >
            {isDownloading ? <CircularProgress size={24} /> : <GetAppIcon />}
          </IconButton>
        )}
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 10,
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <>
          {htmlContent ? (
            <iframe
              ref={iframeRef}
              title="document"
              srcDoc={htmlContent}
              width="100%"
              height="100%"
              sandbox="allow-same-origin allow-scripts allow-popups"
              style={{
                border: "none",
                display: loading ? "none" : "block",
              }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Logo
                style={{
                  width: "120px",
                  height: "120px",
                }}
              />
              <Box
                sx={{
                  marginTop: 2,
                  fontSize: "16px",
                  color: "#333",
                  textAlign: "center",
                }}
              >
                {"Choisir un document à afficher"}
                <br />
                {"depuis la conversation."}
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
