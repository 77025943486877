import React, { useState } from "react";
import CitationDisplay from "./Citation"; // Adjust the import paths as needed
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import IconButton from "@mui/material/IconButton";

const Citations = ({ citations }) => {
  const [show, setShow] = useState(false);

  const [selected, setSelected] = useState(null);

  const handleSelection = async (choice) => {
    // setSelected(selected === choice ? null : choice);
    // if (choice === "down") {
    //   setMessageVisible(false);
    // } else {
    //   setMessageVisible(true);
    //   await dispatch(updateMessageLike(messageId, true, "N/A"));
    //   // setReviewed(true);
    //   setFadeClass("fade-out");
    //   // Set a timeout to fade out the message after 2 seconds
    //   setTimeout(() => {
    //     setFadeClass("hidden");
    //   }, 500);
    //   // Set a timeout to hide the message completely after 4 seconds
    //   setTimeout(() => {
    //     setMessageVisible(false);
    //   }, 600);
    // }
  };

  return (
    <div className="mt-2 mb-2 ml-auto w-11/12 pr-1">
      <div className="flex items-center">
        <IconButton
          onClick={async () => await handleSelection("up")}
          aria-label="thumb up"
          color={selected === "up" ? "primary" : "default"}
        >
          <ThumbUpIcon style={{ fontSize: 18 }} />
        </IconButton>
        <IconButton
          onClick={async () => await handleSelection("down")}
          aria-label="thumb down"
          color={selected === "down" ? "primary" : "default"}
        >
          <ThumbDownIcon style={{ fontSize: 18 }} />
        </IconButton>
      </div>

      <div
        className="mb-2 cursor-pointer text-xs underline flex items-center font-nunito"
        style={{ color: "#BE2165" }}
        onClick={() => setShow(!show)}
      >
        Références:
        <div className="px-2 py-2">
          {!show ? (
            <ArrowDropDownIcon />
          ) : (
            <ArrowDropUpIcon className="-rotate-90" />
          )}
        </div>
      </div>

      {!show && (
        <div className="mb-10 justify-center space-y-2 pr-3">
          {citations.map((citation, index) => (
            <CitationDisplay key={index} citation={citation} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Citations;
