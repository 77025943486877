import { create } from "zustand";
// import { MESSAGE_STATUS, ROLE } from "../types/conversation"; // Adjust import paths as necessary
import { languages, MESSAGE_STATUS, ROLE } from "../../../utils/constants";
// import { languages } from "../constants"; // Adjust import paths as necessary

function saveLanguage(languageCode) {
  localStorage.setItem("languageCode", languageCode);
}

function getSavedLanguage() {
  const languageCodeString = localStorage.getItem("languageCode");

  if (languageCodeString === "ar") {
    return languages.ar;
  }

  if (languageCodeString === "en") {
    return languages.en;
  }

  if (languageCodeString === "fr") {
    return languages.fr;
  }

  return languages.fr;
}

function convertNewlinesToBreaks(text) {
  if (!text) return "";
  return text.replace(/\n/g, "<br/>");
}

const useConversationsStore = create((set) => ({
  messages: [],
  conversations: [],
  setConversations: (conversations) =>
    set({
      conversations,
    }),
  clearConversations: () =>
    set({
      conversations: [],
    }),
  clearMessages: () =>
    set({
      messages: [],
    }),
  activeConversationId: null,
  setActiveConversationId: (id) => set({ activeConversationId: id }),
  editMessage: (messageId, content) =>
    set((state) => {
      console.log("Editing message:", messageId, content); // Logging the input
      console.log("Current messages:", state.messages); // Logging the current messages

      const updatedMessages = state.messages.map((message) => {
        if (message.id === messageId) {
          console.log("Message found, updating:", message); // Logging the message that gets updated
          return { ...message, content, edited: true };
        }
        return message;
      });

      console.log("Updated messages:", updatedMessages); // Logging the updated messages
      return { messages: updatedMessages };
    }),

  checkMessage: (messageId) =>
    set((state) => ({
      messages: state.messages.map((message) =>
        message.id === messageId
          ? { ...message, checked: !message.checked }
          : message
      ),
    })),

  setMessages: (messages) =>
    set({
      messages: messages.map((message) => ({
        ...message,
        content: convertNewlinesToBreaks(message.content),
      })),
    }),
  userSendMessage: (content) =>
    set((state) => {
      return {
        messages: [
          ...state.messages,
          {
            id: Math.random().toString(),
            content: convertNewlinesToBreaks(content),
            role: ROLE.USER,
            status: MESSAGE_STATUS.PENDING,
            created_at: new Date(),
            conversationId: state.activeConversationId || "",
          },
        ],
      };
    }),
  systemSendMessage: (message) =>
    set((state) => {
      const existingMessageIndex = state.messages.findIndex(
        (msg) => msg.id === message.id
      );

      message.content = convertNewlinesToBreaks(message.content);
      // Update the existing message
      if (existingMessageIndex > -1) {
        const updatedMessages = [...state.messages];
        updatedMessages[existingMessageIndex] = message;
        return {
          messages: updatedMessages,
        };
      }
      return {
        messages: [...state.messages, message],
      };
    }),
  currentLanguage: getSavedLanguage(),
  setLanugage: (language) => {
    if (language === "ar") {
      set({ currentLanguage: languages.ar });
      saveLanguage("ar");
      return;
    }
    if (language === "en") {
      set({ currentLanguage: languages.en });
      saveLanguage("en");
      return;
    }
    if (language === "fr") {
      set({ currentLanguage: languages.fr });
      saveLanguage("fr");
      return;
    }

    set({ currentLanguage: languages.fr });
    saveLanguage("fr");
  },
}));

export default useConversationsStore;
