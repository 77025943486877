import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Typography,
  Container,
  Paper,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { clearMessageError, signUp } from "../../redux/actions/authAction";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import CustomButton from "../../components/CustomButton";
import { colors } from "../../utils/colors";

const SignupScreen = () => {
  const dispatch = useDispatch();
  const { loading, error, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [loadingButton, setLoadingButton] = useState(false);

  const [last_name, setLastName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [organization_name, setOrganizationName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  // Error states for validation
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);

  const handleSignup = async () => {
    setLoadingButton(true)
    // Validate required fields
    setLastNameError(last_name.trim() === "");
    setFirstNameError(first_name.trim() === "");

    // Validate email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setEmailError(!isValidEmail);

    // Validate phone number (simple format, can be improved based on country)
    const isValidPhone = /^[0-9]{10}$/.test(phone_number);
    setPhoneError(!isValidPhone);

    // Validate password (at least 6 characters, contains at least one special character)
    const isValidPassword =
      password.length >= 6 && /[!@#$%^&*(),.+?":{}|<>]/.test(password);
    setPasswordError(!isValidPassword);

    // Check if all validations pass
    if (
      last_name.trim() !== "" &&
      first_name.trim() !== "" &&
      isValidEmail &&
      isValidPhone &&
      isValidPassword &&
      password === confirmPassword &&
      acceptTerms
    ) {
      dispatch(
        signUp(
          last_name,
          first_name,
          organization_name,
          phone_number,
          email,
          password,
          navigate
        )
      );
      setLoadingButton(false)
    } else if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas");
      setLoadingButton(false)
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    // Clear message and error state when this component mounts
    dispatch(clearMessageError());
  }, [dispatch]);

  return (
    <Grid
      container
      sx={{
        height: "90vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.bg_color,
      }}
    >
      <Paper
        elevation={4}
        sx={{ borderRadius: "20px", width: "80%", height: "90vh" }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "160px",
                height: "160px",
                backgroundColor: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Logo style={{ width: "140px", height: "140px" }} />
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Container maxWidth="xs">
              <Typography variant="h4" sx={{ mb: 4 }}>
                S'inscrire
              </Typography>

              {error && (
                <Typography color="error" variant="body1">
                  {error}
                </Typography>
              )}

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Nom"
                    margin="normal"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    error={lastNameError}
                    helperText={lastNameError ? "Ce champ est requis." : ""}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Prénom"
                    margin="normal"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={firstNameError}
                    helperText={firstNameError ? "Ce champ est requis." : ""}
                    required
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Entreprise"
                    margin="normal"
                    value={organization_name}
                    onChange={(e) => setOrganizationName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Téléphone"
                    margin="normal"
                    value={phone_number}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    error={phoneError}
                    helperText={
                      phoneError ? "Numéro de téléphone invalide." : ""
                    }
                    required
                  />
                </Grid>
              </Grid>

              <TextField
                fullWidth
                label="Email"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
                helperText={
                  emailError ? "Veuillez entrer une adresse e-mail valide." : ""
                }
                required
              />

              <TextField
                fullWidth
                label="Mot de passe"
                margin="normal"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError}
                helperText={
                  passwordError
                    ? "Le mot de passe doit comporter au moins 6 caractères et contenir un caractère spécial."
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                required
              />

              <TextField
                fullWidth
                label="Confirmer le mot de passe"
                margin="normal"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={password !== confirmPassword && confirmPassword !== ""}
                helperText={
                  password !== confirmPassword && confirmPassword !== ""
                    ? "Les mots de passe ne correspondent pas."
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                required
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                  />
                }
                label="J'accepte les conditions générales de vente"
                required
              />

              <CustomButton
                text="S'inscrire"
                outlined={false}
                loading={loading}
                onClick={handleSignup}
                disabled={!acceptTerms} // Disable button if terms are not accepted
                sx={{ mt: 4, width: "100%" }}
              />
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default SignupScreen;
