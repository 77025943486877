import React, { useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
// import SettingsScreen from './SettingsScreen';
import { colors } from "../../utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/authAction";
import { fetchPrompts, updatePrompt } from "../../redux/actions/userAction";
import { useSnackbar } from "notistack";
import CustomButton from "../../components/CustomButton";

const SettingsScreen = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState(0);
  const [promptText, setPromptText] = useState("");
  const [isSavingPrompt, setIsSavingPrompt] = useState(false);
  const user = useSelector((state) => state.auth?.user?.user);
  const organization_prompt = useSelector(
    (state) => state.user?.organization_prompt
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handlePromptChange = (event) => {
    setPromptText(event.target.value);
  };

  const handleSavePrompt = async () => {
    setIsSavingPrompt(true); // Start loading
    try {
      await dispatch(updatePrompt(user.id, "organization", promptText));
      enqueueSnackbar("Prompt mis-à-jour avec succès!", {
        variant: "success",
      });
    } catch (error) {
      console.error("Failed to update prompt:", error);
      enqueueSnackbar("Erreur lors de l'enregistrement.", {
        variant: "error",
      });
    }
    setIsSavingPrompt(false); // Stop loading
  };
  
  useEffect(() => {
    dispatch(fetchPrompts(user.id, "organization"));
  }, [user]);

  useEffect(() => {
    setPromptText(organization_prompt?.prompt_text);
  }, [organization_prompt]);

  return (
    <Box
      sx={{
        backgroundColor: colors.bg_color,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: "900px",
          padding: "32px",
          borderRadius: "16px",
        }}
      >
        {/* Tabs for navigation */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="params screen tabs"
          centered
        >
          <Tab label="Paramètre généraux" />
          {/* <Tab label="Gestion des utilisateurs" />
          <Tab label="Support" /> */}
          <Tab label="Prompts" />
          <Tab label="Se déconnecter" />
        </Tabs>

        {/* Content Box */}
        <Box sx={{ mt: 4 }}>
          {activeTab === 0 && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Information utilisateur
              </Typography>

              {/* Information Fields */}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label="Nom"
                    variant="outlined"
                    fullWidth
                    value={user.last_name} // Last name from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label="Prénom"
                    variant="outlined"
                    fullWidth
                    value={user.first_name} // First name from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={user.email} // Email from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label="Numéro de téléphone"
                    variant="outlined"
                    fullWidth
                    value={user.phone_number} // Phone number from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>

              {/* Validation Button */}
              {/* <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    padding: "10px 40px",
                    borderRadius: "8px",
                    backgroundColor: "#E0E0E0",
                  }}
                >
                  Valider
                </Button>
              </Box> */}
            </Box>
          )}

          {/* {activeTab === 1 && (
            <Typography>Gestion des utilisateurs content goes here</Typography>
          )} */}
          {/* {activeTab === 2 && (
            <Typography>Support content goes here</Typography>
          )} */}

          {activeTab === 1 && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Prompt de l'organisation
              </Typography>
              <Typography variant="body2" gutterBottom>
                Ce prompt sert à guider l'intelligence artificielle Juridia pour
                personnaliser les réponses et orienter la recherche dans le
                cadre des conversations. Il sera utilisé dans les conversations
                avec les utilisateurs.
              </Typography>

              {/* Multiline Text Input for the Prompt */}
              <TextField
                label="Prompt de l'organisation"
                multiline
                rows={6}
                variant="outlined"
                fullWidth
                value={promptText}
                onChange={handlePromptChange}
                sx={{ mt: 2 }}
              />

              <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                <CustomButton
                  text={"Enregistrer le prompt"}
                  outlined={false} // Filled button
                  loading={isSavingPrompt} // Pass loading state
                  onClick={handleSavePrompt} // Call the create conversation function
                  sx={{
                    padding: "10px 40px",
                    borderRadius: "8px",
                    // backgroundColor: "#1976d2",
                    color: "#fff",
                  }}
                />
              </Box>
            </Box>
          )}
          {activeTab === 2 && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Déconnexion
              </Typography>
              <Typography variant="body1" gutterBottom>
                Êtes-vous sûr de vouloir vous déconnecter ?
              </Typography>
              <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  size="large"
                  color="error"
                  onClick={handleLogout}
                  sx={{
                    padding: "10px 40px",
                    borderRadius: "8px",
                  }}
                >
                  Se déconnecter
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default SettingsScreen;
