import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import SendIcon from "../../../assets/chat/send.png";
import DisabledSendIcon from "../../../assets/chat/disabledSend.png";
import {
  List,
  IconButton,
  Typography,
  TextField,
  Paper,
  Box,
  Grid,
  InputAdornment,
  Divider,
  Button,
  Menu,
  MenuItem,
  ToggleButton,
  CircularProgress,
  styled,
  Tooltip,
  ToggleButtonGroup,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { EventSourcePolyfill } from "event-source-polyfill";
import { v4 as uuidv4 } from "uuid";
// import SendIcon from "@mui/icons-material/Send";
import useMessages from "../../../hooks/useMessage";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GetAppIcon from "@mui/icons-material/GetApp";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import { windowWidth } from "../../../utils/utils";
import { api_subscription_key, backendUrl } from "../../../config";
import { MESSAGE_STATUS } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteConversations,
  fetchConversation,
  setMessages_rd,
  systemSendMessage,
} from "../../../redux/actions/conversationAction";
// import { RenderConversations } from "./RenderConversations";
import { store } from "../../../redux/store";
import { getCitations, windowHeight } from "../../../utils/utils";
import RenderConversations from "./RenderConversations";
import { backendClient } from "../../../api/backend";
import useDocumentStore from "./useDocumentStore";
import useConversationsStore from "./useConversationsStore";
import { colors } from "../../../utils/colors";
import { fontSize } from "../../../utils/fonts";
import { useSnackbar } from "notistack";

// Custom Styled ToggleButton
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  backgroundColor: "#f0f0f0",
  color: "rgba(0, 0, 0, 0.87)",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "black",
  },
  padding: "6px 12px",
  "& .MuiToggleButton-label": {
    paddingTop: "6px",
  },
  "&:not(:first-of-type)": {
    borderRadius: theme.shape.borderRadius,
  },
  "&:first-of-type": {
    borderRadius: theme.shape.borderRadius,
  },
  border: "none",
  "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRight: "none",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      marginLeft: 0,
    },
  },
  "&:hover": {
    backgroundColor: "#d6d6d6", // Slightly darker grey on hover for unselected buttons
  },
}));

const Chat = (props) => {
  //   const documents = useSelector((state) => state.documents.documents);
  const documents = [];

  const { conversationId } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user?.user);
  const user_email = useSelector((state) => state.auth.user?.user?.email);
  const user_id = useSelector((state) => state.auth.user?.user?.id);
  const conversation = useSelector((state) => state.conversations.conversation);
  // const [selectedDocuments, setSelectedDocuments] = useState([]);
  const textFocusRef = useRef();
  const [userMessage, setUserMessage] = useState("");
  const [isMessagePending, setIsMessagePending] = useState(false);
  // const { userSendMessage, systemSendMessage, messages, setMessages } =
  //   useMessages(conversationId || "");
  const { userSendMessage, systemSendMessage, messages, setMessages } =
    useConversationsStore();

  const [filteredMessages, setfilteredMessages] = useState(null);
  const [searchText, setSearchText] = useState(null);
  // Add a loading state for export
  const [isExporting, setIsExporting] = useState(false);
  // Add the following state for mode selection
  const [mode, setMode] = useState("loi");

  // const parentScreen = location.state?.parent_screen;
  const [textInputRows, setTextInputRows] = useState(1);

  const [displayCitations, setDisplayCitations] = useState(false);
  const [isFetchingConversation, setIsFetchingConversation] = useState(false);
  const [hasCheckedMessages, setHasCheckedMessages] = useState(false);

  const { setSelectedCitation, loading, setLoading, selectedCitation } =
    useDocumentStore();

  // Export related states
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const isFirstFetch = useRef(true);
  const hasFetchedMessages = useRef(false);
  const location = useLocation();
  const prevLocationRef = useRef(location.pathname);
  const { enqueueSnackbar } = useSnackbar();
  const eventSourceRef = useRef(null);

  const handleTextChange = (event) => {
    const sanitizedMessage = event.target.value.replace(/<br\s*\/?>/gi, "\n");
    setUserMessage(sanitizedMessage);
  };

  const handleTextSearchChange = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);

    // Filter messages based on content or sub_question answer
    const filteredMessages_ = messages.filter((message) => {
      // Check if the main content includes the search text
      const contentMatch =
        message.content &&
        message.content.toLowerCase().includes(searchText.toLowerCase());

      // Check if any sub-processes contain a matching sub-question answer
      const subQuestionMatch = message.sub_processes?.some(
        (subProcess) =>
          subProcess.metadata_map?.sub_question?.answer &&
          subProcess.metadata_map.sub_question?.answer
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
      );

      // Return true if either content or sub-question answer matches
      return contentMatch || subQuestionMatch;
    });

    setfilteredMessages(filteredMessages_);
  };

  const setSuggestedMessage = (text) => {
    setUserMessage(text);
    if (textFocusRef.current) {
      textFocusRef.current.focus();
    }
  };

  useEffect(() => {
    setSelectedCitation([]);
    setMessages([]);
  }, [location.pathname]);

  // Fetch conversation data when the component mounts
  useEffect(() => {
    const fetchConversationData = async () => {
      if (isFirstFetch.current && conversationId) {
        isFirstFetch.current = false;
        setIsFetchingConversation(true);

        const conversationData = await dispatch(
          fetchConversation(conversationId, user_id, true)
        );

        hasFetchedMessages.current = true; // Mark that messages were fetched
        if (conversationData?.messages?.length) {
          setMessages(conversationData.messages);
        }
        setIsFetchingConversation(false);
      }
    };

    fetchConversationData();
  }, [dispatch, conversationId, user_id, setMessages]);

  useEffect(() => {
    const anyChecked = messages.some((message) => message.checked);
    setHasCheckedMessages(anyChecked);
  }, [messages]);

  const submit = useCallback(() => {
    if (!userMessage || !conversationId) {
      console.error("No user message or conversation ID provided.");
      return;
    }

    setIsMessagePending(true);
    const cleanedMessage = userMessage.replace(/<br\s*\/?>/gi, "\n").trim();

    userSendMessage(cleanedMessage);

    const tokenPayload = backendClient.getToken();
    if (!tokenPayload) {
      console.error("No authentication token available.");
      setIsMessagePending(false);
      setUserMessage("");
      return;
    }

    const token = `${tokenPayload.token_type
      .charAt(0)
      .toUpperCase()}${tokenPayload.token_type.slice(1)} ${encodeURIComponent(
      tokenPayload.access_token
    )}`;

    const encodedUserMessage = encodeURIComponent(userMessage);
    const encodedOrganizationName = encodeURIComponent(user?.organization_name);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const messageEndpoint = `${backendUrl}api/conversation/${conversationId}/message?user_id=${
      user.id
    }&mode=${encodeURIComponent(
      mode
    )}&organization_name=${encodedOrganizationName}&user_message=${encodedUserMessage}&timezone=${encodeURIComponent(
      timezone
    )}`;

    const events = new EventSourcePolyfill(messageEndpoint, {
      headers: {
        Authorization: token,
        Connection: "keep-alive",
      },
      heartbeatTimeout: 1200000,
    });

    console.log("---events", events);
    eventSourceRef.current = events;

    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      systemSendMessage(parsedData);
      // setfilteredMessages([...filteredMessages, parsedData]);
      setfilteredMessages(parsedData);
      // dispatch(systemSendMessage(parsedData));

      if (
        parsedData.status === MESSAGE_STATUS.SUCCESS ||
        parsedData.status === MESSAGE_STATUS.ERROR
      ) {
        events.close();
        setIsMessagePending(false);
        setDisplayCitations(true);
      }
    };

    events.onerror = (event) => {
      // console.error("EventSource failed:", event);
      events.close();
      setIsMessagePending(false);
      // console.log("ERROR SSE", event);

      if (event.status === 403) {
        console.error("Access forbidden (403).");
        // logout();
      }

      // Retry after a custom duration if needed
      // setTimeout(() => submit(), 1000); // Retry after 60 seconds
    };

    setUserMessage("");
  }, [
    conversationId,
    userMessage,
    userSendMessage,
    systemSendMessage,
    setfilteredMessages,
    setDisplayCitations,
    mode,
  ]);

  // Clean up EventSource connection on screen change
  useEffect(() => {
    return () => {
      console.log('CLOSING')
      if (eventSourceRef.current) {
        console.log('closing conversaiton...')
        eventSourceRef.current.close(); // Close connection on unmount
      }
    };
  }, []);

  // Export functionality
  const handleExport = (format: "pdf" | "docx" | "email") => {
    // Set loading state to true
    setIsExporting(true);

    const checkedMessages = messages.reduce((result, message, index) => {
      if (message.checked) {
        console.log("${messages[index - 1].content}", messages[index - 1].id);
        // Check if the previous message exists and its role is "user"
        if (index > 0 && messages[index - 1].role === "user") {
          // Add the previous user message with title
          result.push({
            id: messages[index - 1].id,
            content: `<strong>Utilisateur:</strong> ${
              messages[index - 1].content
            }`, // Add "User" title
          });
        }
        // Add the current checked message with title
        result.push({
          id: message.id,
          content: `<strong>Réponse Juridia:</strong> ${message.content}`, // Add "Juridia" title
        });

        console.log("start citations");
        // Fetch and format citations from the current message
        const citations = getCitations(message);

        console.log("citations", citations);

        if (citations.length > 0) {
          const formattedCitations = citations.map((citation) => {
            return `<br/><strong>Référence :</strong><br/>
            <strong>Titre:</strong> ${citation.long_title}<br/>
            <strong>Section:</strong> ${citation.section_title}<br/>
            <strong>Text:</strong> ${citation.text}<br/><br/>`;
          });

          // Append formatted citations to the result
          result.push({
            id: `${uuidv4()}`,
            content: formattedCitations.join(""), // Join citations as one string
          });
        }
      }
      return result;
    }, []);

    console.log("checkedMessages", checkedMessages);
    backendClient
      .exportMessages(checkedMessages, format, user_email)
      .then(async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        // Adjust the file name based on the export format
        if (format === "email") {
          a.download = "export.eml"; // Use .eml extension for email
        } else {
          a.download = `export.${format}`; // Keep the original logic for pdf, docx, etc.
        }
        a.click();
        enqueueSnackbar(`Messages exportés en format ${format} !`, {
          variant: "success",
        });
      })
      .catch((err) => console.error(err))
      .finally(() => {
        // Set loading state to false
        setIsExporting(false);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to handle mode change
  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
        <BackButton navigate_to={"/conversation"} />

        <Box sx={{ flexGrow: 1, ml: 1 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Rechercher dans la conversation..."
            onChange={handleTextSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box display="flex" justifyContent="center" sx={{ marginLeft: 2 }}>
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={handleModeChange}
            aria-label="Mode selection"
          >
            <StyledToggleButton
              sx={{ fontSize: fontSize.xsmall, mx: 1 }}
              value="loi"
            >
              Loi
            </StyledToggleButton>
            <StyledToggleButton
              sx={{ fontSize: fontSize.xsmall, mx: 1 }}
              value="jp"
            >
              JP
            </StyledToggleButton>
            <StyledToggleButton
              sx={{ fontSize: fontSize.xsmall }}
              value="loi_et_jp"
            >
              Les deux
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Tooltip title="Exporter" placement="bottom">
          <span>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              color="primary"
              sx={{ marginLeft: 2 }}
              disabled={!hasCheckedMessages}
            >
              <GetAppIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
        >
          {isExporting ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <CircularProgress size={24} />
            </Box>
          ) : (
            [
              <MenuItem key="pdf" onClick={() => handleExport("pdf")}>
                <PictureAsPdfIcon className="mr-2" /> PDF
              </MenuItem>,
              <MenuItem key="docx" onClick={() => handleExport("docx")}>
                <DescriptionIcon className="mr-2" /> DOCX
              </MenuItem>,
              <MenuItem key="email" onClick={() => handleExport("email")}>
                <EmailIcon className="mr-2" /> EMAIL
              </MenuItem>,
            ]
          )}
        </Menu>
      </Box>

      <Divider />

      {/* Messages List */}
      <List sx={{ overflowY: "auto", flexGrow: 1, padding: 0 }}>
        {/* <Typography variant="subtitle1" sx={{ p: 2 }}>
          Début de la conversation
        </Typography> */}
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{
            p: 2,
            fontWeight: 16,
            fontSize: fontSize.medium,
            color: "text.secondary", // MUI default text color
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "::before": {
              content: '""',
              flex: 1,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`, // MUI default divider color
              marginRight: "10px",
            },
            "::after": {
              content: '""',
              flex: 1,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`, // MUI default divider color
              marginLeft: "10px",
            },
          }}
        >
          Début de la conversation
        </Typography>

        {filteredMessages?.length > 0 ? (
          <RenderConversations
            messages={filteredMessages}
            setUserMessage={setSuggestedMessage}
            isFetchingConversation={isFetchingConversation}
            documents={[]}
          />
        ) : searchText?.length > 0 ? (
          <Typography variant="subtitle1" sx={{ p: 2 }}>
            No message found.
          </Typography>
        ) : (
          <RenderConversations
            messages={messages}
            setUserMessage={setSuggestedMessage}
            isFetchingConversation={isFetchingConversation}
            documents={[]}
          />
        )}
      </List>

      {/* <Divider /> */}

      {/* Message Input Section */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          p: 0,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Écrire votre message ici..."
          multiline
          minRows={textInputRows}
          maxRows={3}
          value={userMessage}
          onChange={handleTextChange}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.altKey && !event.shiftKey) {
              event.preventDefault(); // Prevent line break
              if (!isMessagePending && userMessage.trim()) submit();
            } else if (
              (event.altKey || event.shiftKey) &&
              event.key === "Enter"
            ) {
              setUserMessage((prev) => `${prev}\n`); // Allows line breaks when Alt or Shift is pressed with Enter
            }
          }}
          ref={textFocusRef}
          InputProps={{
            sx: {
              paddingRight: windowWidth / 120, // Adjust padding to avoid overlap with button
            },
          }}
        />
        <IconButton
          onClick={submit}
          disabled={isMessagePending || !userMessage.trim()}
          sx={{
            position: "absolute",
            right: "10px", // Adjust as needed
            // bottom: "10px", // Adjust as needed
            backgroundColor: "white", // Optional: background for better visibility
            zIndex: 1, // Make sure it’s on top of the text input
          }}
        >
          <img
            src={
              isMessagePending || !userMessage.trim()
                ? DisabledSendIcon
                : SendIcon
            }
            alt="Send"
            style={{ height: windowWidth / 40 }}
          />
        </IconButton>
      </Box>
    </>
  );
};

export default Chat;
