import { backendClient } from "../../api/backend";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  SIGNUP_REQUEST,
  CLEAR_AUTH_STATE,
  CLEAR_MESSAGE_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
} from "./types";

// Login
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const login = (email, password, navigate) => {
  return async (dispatch) => {
    dispatch(loginRequest()); // Dispatch the request action to indicate the login process has started
    try {
      // Call the backend login function
      const data = await backendClient.login(email, password);
      console.log("dataLogin", data);

      // Dispatch success action with the response data
      dispatch(loginSuccess(data));
      console.log("navigating");
      navigate("/");
    } catch (error) {
      let errorMessage = "An unexpected error occurred.";

      // Handle specific error status codes
      if (error.status === 401) {
        errorMessage = "Email or password is incorrect.";
      } else if (error.status === 403) {
        errorMessage = "You are not allowed to log in.";
      }

      // Dispatch failure action in case of error
      dispatch(loginFailure(errorMessage));
    }
  };
};

// Sign Up
export const signUpRequest = () => ({
  type: SIGNUP_REQUEST,
});

export const signUpSuccess = (user) => ({
  type: SIGNUP_SUCCESS,
  payload: user,
});

export const signUpFailure = (error) => ({
  type: SIGNUP_FAILURE,
  payload: error,
});

export const signUp = (
  last_name,
  first_name,
  organization_name,
  phone_number,
  email,
  password,
  navigate
) => {
  return async (dispatch) => {
    dispatch(signUpRequest()); // Dispatch the request action to indicate the signup process has started
    try {
      // Call the backend signup function
      const data = await backendClient.signUp(
        last_name,
        first_name,
        organization_name,
        phone_number,
        email,
        password
      );
      console.log("dataSignUp", data);

      // Dispatch success action with the response data
      dispatch(signUpSuccess(data));

      console.log("navigating");
      if (data?.registered) {
        console.log("OK");
        navigate("/signup/confirmation");
      }
    } catch (error) {
      let errorMessage = "An unexpected error occurred during registration.";

      // Handle specific error status codes
      if (error.status === 409) {
        errorMessage = "A user with this email already exists.";
      } else if (error.status === 500) {
        errorMessage =
          "An internal server error occurred. Please try again later.";
      }

      // Dispatch failure action in case of error
      dispatch(signUpFailure(errorMessage));
    }
  };
};

// Sign Out
export const logout = () => {
  return (dispatch) => {
    // Clear user data
    dispatch({ type: LOGOUT });
  };
};

// Forgot Password
export const forgotPasswordRequest = () => ({
  type: FORGOT_PASSWORD_REQUEST,
});

export const forgotPasswordSuccess = (message) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: message,
});

export const forgotPasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
});

export const forgotPassword = (email) => {
  return async (dispatch) => {
    dispatch(forgotPasswordRequest());
    try {
      const res = await backendClient.forgotPassword(email);
      const data = await res.json();

      if (!res.ok) {
        throw new Error(data.detail || "Failed to send password reset email.");
      }

      dispatch(
        forgotPasswordSuccess(
          "Un email de réinitialisation de mot de passe vous a été envoyé."
        )
      );
    } catch (error) {
      let errorMessage = "An unexpected error occurred.";
      if (error.message.includes("404")) {
        errorMessage = "User with this email does not exist.";
      } else if (error.message.includes("401")) {
        errorMessage = "Unauthorized request.";
      }
      dispatch(forgotPasswordFailure(errorMessage));
    }
  };
};

// Reset Password
export const resetPassword = (token, newPassword) => {
  return async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    try {
      const res = await backendClient.resetPassword(token, newPassword);
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: "Mot de passe réinitialisé avec succès.",
      });
    } catch (error) {
      const errorMessage =
        error.message || "An unexpected error occurred during password reset.";
      dispatch({ type: RESET_PASSWORD_FAILURE, payload: errorMessage });
    }
  };
};

export const clearAuthState = () => ({
  type: CLEAR_AUTH_STATE,
});
export const clearMessageError = () => ({
  type: CLEAR_MESSAGE_ERROR,
});
