import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "./router/AppRouter";
import { SnackbarProvider } from "notistack";

const App = () => {
  return (
    <React.StrictMode>
      <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppRouter />
          </PersistGate>
        </Provider>
      </SnackbarProvider>
    </React.StrictMode>
  );
};

export default App;
